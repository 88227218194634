import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '../../../hooks/usePage.js';
import mainStyles from '../../../assets/js/mainStyles.js';

const useStyles = makeStyles(mainStyles);

const SelectSubcategory = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setTitle } = usePage();

  useEffect(() => {
    setTitle('P3D 2');
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.contentMW}>
        <Typography className={classes.textBox} style={{ fontSize: 20 }}>
          Sélectionnez une rubrique :
        </Typography>
        <Box align="center" className={classes.flexBoxColumn}>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/p3d2/modules')}
            variant="outlined"
            size="large"
          >
            Modules
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/p3d2/components')}
            variant="outlined"
            size="large"
          >
            Composants
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/p3d2/accessories')}
            variant="outlined"
            size="large"
          >
            Accessoires Complémentaires
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/p3d2/configurators')}
            variant="outlined"
            size="large"
          >
            Configurateurs
          </Button>
          <Button
            className={classes.outlinedBtn}
            onClick={() => history.push('/security/p3d2/doc-technical')}
            variant="outlined"
            size="large"
          >
            Docs techniques
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SelectSubcategory;
