import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container, makeStyles, Button } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const Accessor = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [accessor, setAccessor] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/p3d2-accessories/${id}`);
      setAccessor(response);
    };
    setTitle('Accessoir Complementaires');
    await fetch();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <div className={classes.secTitle}>
          <span>{accessor?.title} </span>
        </div>
        <Button
          className={classes.outlinedBtnGrey}
          onClick={() => history.push(`/security/p3d2/accessories/${accessor.id}/description`)}
        >
          Descriptif
        </Button>
        <Button
          className={classes.outlinedBtnGrey}
          onClick={() => history.push(`/security/p3d2/accessories/${accessor.id}/implementation`)}
        >
          Mise en œuvre
        </Button>
      </Box>
    </Container>
  );
};

export default Accessor;
