import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Container, makeStyles, Button } from '@material-ui/core';
import mainStyles from '@/assets/js/mainStyles.js';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';

const useStyles = makeStyles(mainStyles);

const Component = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [component, setComponent] = useState({});
  const { setTitle } = usePage();

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/p3d2-components/${id}`);
      setComponent(response);
    };
    setTitle('Composants');

    await fetch();
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.content}>
        <div className={classes.secTitle}>
          <span>{component?.title} </span>
        </div>
        <Button
          className={classes.outlinedBtnGrey}
          onClick={() => history.push(`/security/p3d2/components/${component.id}/description`)}
        >
          Descriptif
        </Button>
        <Button
          className={classes.outlinedBtnGrey}
          onClick={() => history.push(`/security/p3d2/components/${component.id}/implementation`)}
        >
          Mise en œuvre
        </Button>
        <Button
          className={classes.mainBtn}
          onClick={() => window.open('https://www.sateco.fr/fr/faq.html')}
          variant="outlined"
          size="large"
        >
          FAQ
        </Button>
      </Box>
    </Container>
  );
};

export default Component;
