import React, { useEffect } from 'react';
import { Container, Box, makeStyles, Badge, Card, CardMedia, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePage from '../../hooks/usePage.js';
import mainStyles from '../../assets/js/mainStyles.js';

import pca from '../../assets/img/pca.jpg';
import pce from '../../assets/img/pce.jpg';

const useStyles = makeStyles(mainStyles);

const SelectCategory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setTitle } = usePage();

  const waitingPush = (where) =>
    new Promise((resolve) => {
      setTimeout(() => {
        history.push(where);
        resolve();
      }, 222);
    });

  useEffect(() => {
    setTitle('Sécurité');
  }, []);

  return (
    <Container maxWidth="md">
      <Box className={classes.root2}>
        <div className={classes.textBox}>Vous cherchez des informations sur :</div>
        <Grid item xs={12} md={12} className={classes.cardItem2} onClick={() => waitingPush('/security/p3d2')}>
          <Badge className={classes.badge2}>Plate-forme P3D 2</Badge>
          <Card>
            <CardMedia className={classes.cardImg2Big} image={pca} />
          </Card>
        </Grid>
        <Grid item xs={12} md={12} className={classes.cardItem2} onClick={() => waitingPush('/security/pce-2')}>
          <Badge className={classes.badge2}>Podium Cage Escalier PCE 2</Badge>
          <Card>
            <CardMedia className={classes.cardImg2} image={pce} />
          </Card>
        </Grid>
        <Grid item xs={12} md={12} className={classes.cardItem2} onClick={() => waitingPush('/security/pca-2')}>
          <Badge className={classes.badge2}>Podium Cage Ascenseur PCA 2</Badge>
          <Card>
            <CardMedia className={classes.cardImg2Big} image={pca} />
          </Card>
        </Grid>
      </Box>
    </Container>
  );
};

export default SelectCategory;
