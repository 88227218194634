import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, CardMedia, Container, makeStyles } from '@material-ui/core';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import usePage from '@/hooks/usePage.js';
import Request from '@/helpers/request.js';
import Constants from '@/constants.js';
import mainStyles from '@/assets/js/mainStyles.js';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const useStyles = makeStyles(mainStyles);

const InnovationDocuments = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [innovation, setInnovation] = useState({});
  const { setTitle } = usePage();
  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  useEffect(async () => {
    const fetch = async () => {
      const response = await Request.get(`/innovations/${id}`);
      setInnovation(response);
    };
    setTitle('Innovations');
    await fetch();
  }, []);
  const innovationData = innovation.documents
    ? innovation.documents
    : {
        file: null,
        image: null,
      };

  return (
    <Container maxWidth="md">
      <Box className={classes.descRoot}>
        <div className={classes.subTitle} style={{ fontSize: 23, textAlign: 'center' }}>
          {innovation.title}
        </div>
        {innovationData.image && (
          <Card className={classes.imgCard}>
            <CardMedia
              className={classes.imgItem}
              component="img"
              image={`${Constants.BASE_URL}/${innovationData.image.path} `}
            />
          </Card>
        )}
        {innovationData.file && (
          <div className={classes.pdfViewer}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <div style={{ textAlign: 'center' }}>
                <ZoomOut>
                  {(props) => (
                    <Button
                      style={{ width: 100, height: 20 }}
                      className={classes.outlinedBtnGrey}
                      onClick={props.onClick}
                    >
                      ZoomOut
                    </Button>
                  )}
                </ZoomOut>
                <CurrentScale>
                  {(props) => <span style={{ padding: 10 }}>{`${Math.round(props.scale * 100)}%`}</span>}
                </CurrentScale>
                <ZoomIn>
                  {(props) => (
                    <Button
                      style={{ width: 100, height: 20 }}
                      className={classes.outlinedBtnGrey}
                      onClick={props.onClick}
                    >
                      ZoomIn
                    </Button>
                  )}
                </ZoomIn>
              </div>
              <Viewer
                defaultScale={SpecialZoomLevel.PageFit}
                plugins={[zoomPluginInstance]}
                fileUrl={`${Constants.BASE_URL}/${innovationData.file.path} `}
              />
            </Worker>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default InnovationDocuments;
